
h1 {
		line-height : 160% !important;
}
td {
		line-height : 160%!important;
}
a.l {
		text-decoration : underline!important;
}
a:hover {
		text-decoration : underline!important;
}
a {
		text-decoration : underline!important;
}
a.l:hover {
		text-decoration : underline!important;
}
div {
		line-height : 160%!important;
}
.unleaded{
	padding:4px 15px 0 15px!important;
	margin:0 0 0 0!important;
	font-size: 16px!important;
	font-weight: bold!important;
}


/* Start StyleCSS */
/* 
d58f1f
2c2c2c
*/
html,
body {
	height: 100%!important;
}
body {
	font-family: montserrat, sans-serif!important;
	font-size: 16px!important;
	-ms-overflow-style: scrollbar!important;
	background: #f2f2f2!important;
	color: #000000!important;
}
input, textarea {
	font-family: inherit !important;
}
#CP_Contact .form-container {
	max-width:600px!important;
}
#CP_StyleContainer {
	position: relative!important;
	min-height: 100%!important;
}
#CP_StyleContainer.home #CP_Content {
	overflow:hidden!important;
}
#CP_Content {
  background: white!important;
  color: black!important;
}
#CP_Services {}

.container-fluid {
	max-width: 1600px!important;
	margin:0 auto!important;
}
a {
	color: #d58f1f!important;
	text-decoration: underline!important;
}
a:hover,
a:focus {
	color: #d58f1f!important;
    text-decoration: none!important;
}
.parallax,
.bg-image { 
	position: relative!important;
	background-size: cover!important;
	background-position: 50% 50%!important;
	background-repeat: no-repeat!important;
}
.bg-image-right {
	background-size:contain!important;
	background-position: 100% 50%!important;
	background-repeat: no-repeat!important;
}
.parallax { 
	background-attachment: fixed!important;
}
.color-1 {
	color: #d58f1f !important;
	border-color: #d58f1f !important;
}
.bg-color-1 {
	background-color: #d58f1f !important;
	border-color: #d58f1f !important;
	color: #fff !important;
}
.color-2 {
	color: #2c2c2c !important;
	border-color: #2c2c2c !important;
}
.bg-color-2 {
	background-color: #2c2c2c !important;
	border-color: #2c2c2c !important;
	color: #fff !important;
}
.bg-color-3 {
	background-color: #d9d9d9 !important;
	border-color: #d9d9d9 !important;
}
.white {
	color: #fff !important;
}
.bg-white {
	background-color: #fff !important;
	border-color: #fff !important;
}
.black {
	color: #000 !important;
}
.bg-black {
	background-color: #000 !important;
	border-color: #000 !important;
	color: #fff !important;
}
.bg-light {
    background-color: #f2f2f2 !important;
    border-color: #f2f2f2 !important;
}
.form-control::-webkit-input-placeholder {
	color:#000!important;
}
.form-control::-moz-placeholder {
	color:#000!important;
}
.form-control:-ms-input-placeholder {
	color:#000!important;
}
.form-control::-ms-input-placeholder {
	color:#000!important;
}
.form-control::placeholder,
.form-control {
	color:#000!important;
}
#CP_phone {
	line-height: 120%!important;
	font-weight: 500!important;
	letter-spacing: 1px!important;
}
hr.line {
    border-bottom: 3px solid #000!important;
    width: 100px!important;
    opacity: .5!important;
}
h1, .h1, h2, .h2, h3, .h3 {
	font-family: abril-display, serif!important;
	font-weight: 600!important;
	font-style: italic!important;
}
.serif {
	font-family: abril-display, serif!important;
}
.text-shadow {
    text-shadow: 0 0 5px rgba(0, 0, 0, .75)!important;
}
.btn,
.btn:focus,
.btn:active {
	position: relative!important;
	text-decoration: none!important;
	outline: 0!important;
	border-radius: 0!important;
	text-transform: uppercase!important;
	border-color: transparent!important;
	font-weight: 500!important;
	-o-transition: all 0.1s ease-in!important;
	-webkit-transition: all 0.1s ease-in!important;
	transition: all 0.1s ease-in!important;
	font-size: 14px!important;
	letter-spacing: 1px!important;
}
.btn:hover,
.btn:active:hover {
	text-shadow:0 3px 5px rgba(0,0,0,.3)!important;
	-webkit-filter: brightness(1.2)!important;
	        filter: brightness(1.2)!important;
}
.modal-content {
	-webkit-box-shadow: 0 15px 30px rgba(0,0,0,.6)!important;
	box-shadow: 0 15px 30px rgba(0,0,0,.6)!important;
	border:0!important;
	border-radius: 0!important;
	overflow: hidden!important;
}
.modal-content .btn {
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
}
.modal-header {
	border-radius: 0!important;
}
.form-control,
.input-lg,
.input-group-lg {
    border-radius: 0!important;
}
.btn:focus,
.btn:hover:focus {
	outline: 0!important;
}
.btn:active {
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
}
.btn-default,
.btn-default:focus,
.btn-default:active,
.btn-default:hover,
.btn-default:active:hover {
    color:#fff!important;
    background: #d58f1f!important;
    border-color:#d58f1f!important;
}
.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover,
.btn-primary:active:hover {
    color:#fff!important;
    background:#2c2c2c!important;
	border-color:#2c2c2c!important;
}

/* Header */

#CP_SkipNav {
	opacity: 0!important;
	left: 50%!important;
	top: -34px!important;
	min-width: 220px!important;
	margin-left: -110px!important;
	position: absolute!important;
	z-index: 100!important;
	border-radius: 0 0 4px 4px!important;
	-moz-border-radius: 0 0 4px 4px!important;
	-webkit-border-radius: 0 0 4px 4px!important;
	z-index: 10000!important;
}
#CP_SkipNav:focus {
	opacity: 1!important;
	position: absolute!important;
	top: 0!important;
}
#CP_TopBar {
	background: #2c2c2c!important;
	color: #fff!important;
}
#CP_TopBar a {
	text-decoration: none!important;
	padding: 15px!important;
	line-height: 20px!important;
	text-transform: uppercase!important;
	letter-spacing: 1px!important;
	font-size: 14px!important;
	color:#fff!important;
}
#CP_TopBar .spacer {
	display:none!important;
	opacity: .5!important;
}
#CP_Header {
    position: relative!important;
}
#logo {
	text-decoration: none!important;
}
#logo h2 {
	font-family: abril-display, serif!important;
	margin: 0 0 -5px!important;
	padding: 10px 0!important;
	line-height: 30px!important;
	font-size:32px!important;
	text-transform:uppercase!important;
	font-weight: 700!important;
	font-style:normal!important;
}
#logo h3 {
	margin: 0!important;
	padding:0!important;
	line-height: 20px!important;
	font-size:18px!important;
	text-transform:uppercase!important;
	color:#2c2c2c!important;
}
#logo img {
    max-width:400px!important;
	width:100%!important;
}
.parallax .container-fluid {
	position: relative!important;
}
#CP_Hero .overlay {
	background: rgba(0,0,0,.4)!important;
	background: -o-linear-gradient(bottom, rgba(0,0,0,.4) 0%, rgba(0,0,0,.1) 100%)!important;
	background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,.4)), color-stop(100%, rgba(0,0,0,.1)))!important;
	background: linear-gradient(0deg, rgba(0,0,0,.4) 0%, rgba(0,0,0,.1) 100%)!important;
}
.internal #CP_Hero .overlay,
.overlay {
	position: absolute!important;
	top:0!important;
	left:0!important;
	width:100%!important;
	height:100%!important;
	background: rgba(0,0,0,.4)!important;
}
.overlay:before {
	content:""!important;
	position:absolute!important;
	top:0!important;
	left:0!important;
	width:calc(100% - 50px)!important;
	height:calc(100% - 50px)!important;
	margin-top: 25px!important;
	margin-left: 25px!important;
	border: 3px solid rgb(255 255 255 / 50%)!important;
}
#CP_Hero {
    background-image: url('../public/assets/landing/hero-2.jpg')!important;
    background-position: 50% 50%!important;
    position: relative!important;
    overflow: hidden!important;
	height: 20vw!important;
min-height: calc(230px + 20vw)!important;
}
.full #CP_Hero {
    max-height: calc(100vh - 50px)!important;
}
.internal #CP_Hero {
	background-image:url('../public/assets/landing/hero-2.jpg')!important;
    background-position: 50% 40%!important;
    height: calc(300px + 5vw)!important;
	min-height: auto!important;
}
#CP_Slider {
	background-image: url('../public/assets/landing/parallax-3.jpg')!important;
	background-position: 50% 100%!important;
	height: calc(300px + 10vw)!important;
	max-height: 600px!important;
}
#CP_Hero #title {
	margin: 30px 0!important;
	padding: 0!important;
}
.lead {
	font-size: 1.25rem!important;
	font-weight: 300!important;
}

.view .mask {
	width: 100%!important;
	height: 100%!important;
	position: absolute!important;
	overflow: hidden!important;
	top: 0!important;
	left: 0!important;
	background-attachment: fixed!important;
}
.view {
	overflow: hidden!important;
	position: relative!important;
	cursor: default!important;
}
.view a {
	position: relative!important;
}
.hm-zoom:hover img {
	-webkit-transform: scale(1.1)!important;
	-ms-transform: scale(1.1)!important;
	transform: scale(1.1)!important;
	backface-visibility: hidden!important;
	-webkit-backface-visibility: hidden!important;
	transform-style: preserve-3d!important;
	-webkit-transform-style: preserve-3d!important;
}
.hm-zoom img {
	-webkit-transition: all .2s linear!important;
	-o-transition: all .2s linear!important;
	transition: all .2s linear!important;
	transform: all .2s linear!important;
	-ms-transform: all .2s linear!important;
	backface-visibility: hidden!important;
	-webkit-backface-visibility: hidden!important;
	transform-style: preserve-3d!important;
	-webkit-transform-style: preserve-3d!important;
	width: 100%!important;
}
#CP_Section a,
#CP_Breadcrumbs a {
	text-decoration:none!important;
}
#CP_Section a:hover,
#CP_Breadcrumbs a:hover {
	color:#000!important;
}
#CP_Breadcrumbs a:after {
	content: "/"!important;
    color: rgb(0 0 0 / 20%)!important;
    margin: 0 4px 0 10px!important;
}
.nav-pills .nav-link {
	border-radius:0!important;
    color: #000!important;
	padding:.5rem!important;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff!important;
    background-color: #d58f1f!important;
}
#CP_Icons .icon .fa {
    -webkit-transition: all 0.3s ease!important;
    -o-transition: all 0.3s ease!important;
    transition: all 0.3s ease!important;
    line-height:60px!important;
    font-size:3em!important;
}
#CP_Icons .icon:hover .fa {
    color:#000!important;
    font-size:4em!important;
}
#CP_Icons .icon p {
    color:#000!important;
}
footer {
	color: #fff!important;
}
footer a,
footer a:focus {
    text-decoration: none!important;
	color:#fff!important;
}
footer a:hover {
	text-decoration: underline!important;
	color:#fff!important;
}
#CP_SideScroll {
    position: fixed!important;
    bottom: 25px!important;
    right: 25px!important;
    /* width:60px!important; */
    min-height:60px!important;
    border-radius: 0!important;
    line-height: 60px!important;
    font-size:24px!important;
    text-align: center!important;
    z-index: 1020!important;
	pointer-events: none!important;
	opacity: 0!important;
}
#CP_SideScroll:before {
	/* content:""!important; */
	position:absolute!important;
	top:0!important;
	left:0!important;
	width: calc(100% - 10px)!important;
	height: calc(100% - 10px)!important;
	margin-top: 5px!important;
	margin-left: 5px!important;
	border: 3px solid rgb(255 255 255 / 50%)!important;
}
#CP_SideScroll a, #CP_SideScroll a:active, #CP_SideScroll a:focus ,#CP_SideScroll button {
	position: relative!important;
	text-decoration: none!important;
	display: block!important;
	cursor: pointer!important;
	width:60px!important;
	height:60px!important;
	line-height: 60px!important;
	font-size:24px!important;
	text-align: center!important;
	color:#fff!important;
	-webkit-transition: all 0.1s ease-in!important;
	-o-transition: all 0.1s ease-in!important;
	transition: all 0.1s ease-in!important;
	border: 0!important;
	background:transparent!important;
	/* margin: 0!important; */
	padding: 0!important;
}
#CP_SideScroll .badge {
	display:none!important;
	border-radius: 25px!important;
	font-size:12px!important;
	width:20px!important;
	height:20px!important;
	line-height:20px!important;
	margin:0!important;
	padding:0!important;
	position: absolute!important;
	top: -5px!important;
	right: -5px!important;
	background-color:#ff5722!important;
	color:#fff!important;
	-webkit-animation-duration: 30s!important;
	        animation-duration: 30s!important;
	-webkit-animation-iteration-count: infinite!important;
	        animation-iteration-count: infinite!important;
	-webkit-animation-name: bounce!important;
	        animation-name: bounce!important;
	-webkit-animation-timing-function: ease!important;
	        animation-timing-function: ease!important;
}
@-webkit-keyframes bounce {
	5%   { -webkit-transform: translateY(0)!important; transform: translateY(0)!important; }
	6%  { -webkit-transform: translateY(-20px)!important; transform: translateY(-20px)!important; }
	6.6%  { -webkit-transform: translateY(0)!important; transform: translateY(0)!important; }
	7%  { -webkit-transform: translateY(-8px)!important; transform: translateY(-8px)!important; }
	7.4%  { -webkit-transform: translateY(0)!important; transform: translateY(0)!important; }
}
@keyframes bounce {
	5%   { -webkit-transform: translateY(0)!important; transform: translateY(0)!important; }
	6%  { -webkit-transform: translateY(-20px)!important; transform: translateY(-20px)!important; }
	6.6%  { -webkit-transform: translateY(0)!important; transform: translateY(0)!important; }
	7%  { -webkit-transform: translateY(-8px)!important; transform: translateY(-8px)!important; }
	7.4%  { -webkit-transform: translateY(0)!important; transform: translateY(0)!important; }
}
.tooltip.show {
    opacity: 1!important;
}
.tooltip-inner {
    max-width: 250px!important;
    padding: 10px 15px!important;
    color: #000!important;
    text-align: center!important;
    background-color: #fff!important;
	border-radius: .25rem!important;
	-webkit-box-shadow: 0 10px 15px rgba(0,0,0,.3)!important;
    box-shadow: 0 10px 15px rgba(0,0,0,.3)!important;
}
.tooltip.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: #fff!important;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: #fff!important;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: #fff!important;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before {
	border-right-color: #fff!important;
}
#CP_SideScroll button {
	background: rgb(255 255 255 / 15%)!important;
}
#CP_SideScroll button a:hover {
	text-decoration: none!important;
	font-size:28px!important;
}
#CP_SideScroll {
	opacity:0!important;
    -webkit-transition: all 0.3s ease!important;
    -o-transition: all 0.3s ease!important;
    transition: all 0.3s ease!important;
}
#CP_SideScroll a.scrollToTop:hover {
	background:transparent!important;
	line-height:40px!important;
}

/* SmartMenu */

ul.nav.navbar-nav {
	display: none!important;
}
#siteManager,
img[src$="edithtml.gif"] {
	z-index: 1002 !important;
}
#CP_StyleOverlay {
	display: none!important;
}
#CP_SmartMenu .active>a {
	color:#d58f1f!important;
}
#CP_SmartMenu {
	-webkit-box-sizing: border-box!important;
	        box-sizing: border-box!important;
	position: relative!important;
	z-index: 9999!important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)!important;
    display:block!important;
    z-index: inherit!important;
}
#modalMenu #CP_SmartMenu {
    display:block!important;
}
#CP_SmartMenu,
#CP_SmartMenu ul,
#CP_SmartMenu li {
	display: block!important;
	list-style: none!important;
	margin: 0!important;
	padding: 0!important;
	line-height: normal!important;
	direction: ltr!important;
	text-align: left!important;
}
#CP_SmartMenu ul {
	display: none!important;
	background: rgba(0, 0, 0, 0.05)!important;
}
#CP_SmartMenu li,
#CP_SmartMenu a {
	position: relative!important;
}
#CP_SmartMenu a {
	display: block!important;
	-webkit-transition: none!important;
	-o-transition: none!important;
	transition: none!important;
}
#CP_SmartMenu::after {
	content: ""!important;
	display: block!important;
	height: 0!important;
	font: 0px/0 serif!important;
	clear: both!important;
	overflow: hidden!important;
}
#CP_SmartMenu *,
#CP_SmartMenu *::before,
#CP_SmartMenu *::after {
	-webkit-box-sizing: inherit!important;
	        box-sizing: inherit!important;
}
#CP_SmartMenu a,
#CP_SmartMenu a:hover,
#CP_SmartMenu a:focus,
#CP_SmartMenu a:active {
	padding: 15px 10px!important;
	color: #fff!important;
	line-height: 20px!important;
	text-decoration: none!important;
}
#CP_SmartMenu a:hover {
	background: rgba(0, 0, 0, 0.025)!important;
}
#CP_SmartMenu a .sub-arrow {
	position: absolute!important;
	top: 50%!important;
	margin-top: -15px!important;
	left: auto!important;
	right: 10px!important;
	width: 30px!important;
	height: 30px!important;
	overflow: hidden!important;
	font-size: 24px!important;
	text-align: center!important;
	text-shadow: none!important;
	border: 1px solid rgba(0, 0, 0, 0.1)!important;
	background: rgba(255, 255, 255, .8)!important;
	border-radius: 20px!important;
}
#CP_SmartMenu a .sub-arrow:hover {
	background: #fff!important;
}
#CP_SmartMenu a .sub-arrow::before,
#CP_SmartMenu a .sub-arrow::after {
	content: ' '!important;
    right: 50%!important;
    top: 50%!important;
    text-align: center!important;
    line-height: 50px!important;
	display: block!important;
    position: absolute!important;
    background: #d58f1f!important;
}
#CP_SmartMenu a .sub-arrow::before {
	margin-right: -5px!important;
	margin-top: -1px!important;
	width: 10px!important;
	height: 2px!important;
}
#CP_SmartMenu a .sub-arrow::after {
	margin-right: -1px!important;
	margin-top: -5px!important;
	width: 2px!important;
	height: 10px!important;
}
#CP_SmartMenu a.highlighted .sub-arrow::after {
	display: none!important;
}
#CP_SmartMenu li {
	border-top: 1px solid rgba(0, 0, 0, 0.05)!important;
}
#CP_SmartMenu>li:last-child {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05)!important;
}
#CP_SmartMenu ul a,
#CP_SmartMenu ul a:hover,
#CP_SmartMenu ul a:focus,
#CP_SmartMenu ul a:active {
	font-weight: normal!important;
	border-left: 8px solid transparent!important;
    -webkit-transition: all 0.3s ease!important;
    -o-transition: all 0.3s ease!important;
    transition: all 0.3s ease!important;
    color:#000000!important;
}
#CP_SmartMenu ul ul a,
#CP_SmartMenu ul ul a:hover,
#CP_SmartMenu ul ul a:focus,
#CP_SmartMenu ul ul a:active {
	border-left: 16px solid transparent!important;
}
#CP_SmartMenu ul ul ul a,
#CP_SmartMenu ul ul ul a:hover,
#CP_SmartMenu ul ul ul a:focus,
#CP_SmartMenu ul ul ul a:active {
	border-left: 24px solid transparent!important;
}
#CP_SmartMenu ul ul ul ul a,
#CP_SmartMenu ul ul ul ul a:hover,
#CP_SmartMenu ul ul ul ul a:focus,
#CP_SmartMenu ul ul ul ul a:active {
	border-left: 32px solid transparent!important;
}
#CP_SmartMenu ul ul ul ul ul a,
#CP_SmartMenu ul ul ul ul ul a:hover,
#CP_SmartMenu ul ul ul ul ul a:focus,
#CP_SmartMenu ul ul ul ul ul a:active {
	border-left: 40px solid transparent!important;
}
#Toggle {
	display:block!important;
}
#menu {
    display: none!important;
}
#CP_SmartMenu a,
#CP_SmartMenu a:hover,
#CP_SmartMenu a:focus,
#CP_SmartMenu a:active {
	color: #000!important;
}

/* Switch to desktop layout */

@media (min-width: 992px) and (any-pointer:fine), (min-width: 992px) and (any-hover:hover) {
	#CP_TopBar .fa,
	#Toggle {
        display: none!important;
    }
	#CP_TopBar .spacer {
		display:inline!important;
	}
    #menu {
		display: block!important;
		height: 50px!important;
	}
    #CP_SmartMenu {
        display: inline-block!important;
    }
	#CP_SmartMenu a,
	#CP_SmartMenu a:hover,
	#CP_SmartMenu a:focus,
	#CP_SmartMenu a:active {
		padding: 15px 25px 15px 0!important;
		color: #fff!important;
		margin-right: 1vw!important;
		background: transparent!important;
		text-transform: uppercase!important;
		letter-spacing: 1px!important;
		font-size: 14px!important;
	}
	#CP_SmartMenu ul {
		position: absolute!important;
		width: 12em!important;
		background: #fff!important;
		-webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3)!important;
		        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3)!important;
	}
	#CP_SmartMenu li {
		float: left!important;
	}
	#CP_SmartMenu>li:last-child {
		border-bottom: 0!important;
	}
	#CP_SmartMenu ul li {
		float: none!important;
	}
	#CP_SmartMenu a {
		white-space: nowrap!important;
    }
	#CP_SmartMenu ul a,
	#CP_SmartMenu ul a:hover,
	#CP_SmartMenu ul a:focus,
	#CP_SmartMenu ul a:active {
		text-transform: none!important;
		white-space: normal!important;
		padding: 8px 1.5em!important;
		margin:0!important;
	}
	#CP_SmartMenu .sm-nowrap>li>a,
	#CP_SmartMenu .sm-nowrap>li> :not(ul) a {
		white-space: nowrap!important;
		letter-spacing: 0!important;
		margin: 0!important;
	}
	#CP_SmartMenu a.has-submenu,
	#CP_SmartMenu a.has-submenu.current {
		padding-right: 1.5em!important;
	}
	#CP_SmartMenu ul a:hover,
	#CP_SmartMenu ul a:focus,
	#CP_SmartMenu ul a:active,
	#CP_SmartMenu ul a.highlighted {
		background:rgba(0, 0, 0, .05)!important;
	}
	#CP_SmartMenu a .sub-arrow {
		top: 50%!important;
		margin-top: -1px!important;
		right: 0!important;
		width: 0!important;
		height: 0!important;
		border-left: 4px solid transparent!important;
		border-right: 4px solid transparent!important;
		border-bottom: 0!important;
		border-top:0!important;
		background: transparent!important;
		border-radius: 0!important;
		border-top: 4px solid #000!important;
        display: none!important;
	}
	#CP_SmartMenu a .sub-arrow:hover {
		background: transparent!important;
	}
	#CP_SmartMenu ul a .sub-arrow {
		margin-top: -5px!important;
		border-top: 4px solid transparent!important;
		border-bottom: 4px solid transparent!important;
		border-right: 0!important;
		right: 10px!important;
        display: block!important;
        border-left: 4px solid #000!important;
	}
	#CP_SmartMenu li {
		border-top: 0!important;
	}
	#CP_SmartMenu>li:first-child {
		border-left: 0!important;
	}
	#CP_SmartMenu ul a {
		border: 0 !important;
	}
	#CP_SmartMenu ul a.has-submenu {
		padding-right: 25px!important;
		margin: 0!important;
	}
	#CP_SmartMenu ul>li {
		border-left: 0!important;
		border-top: 0!important;
	}
	#CP_SmartMenu ul>li:first-child {
		border-top: 0!important;
	}
	#CP_SmartMenu ul>li {
		border-top: 1px solid rgba(0, 0, 0, .05)!important;
	}
	#CP_SmartMenu .scroll-up,
	#CP_SmartMenu .scroll-down {
		position: absolute!important;
		display: none!important;
		visibility: hidden!important;
		overflow: hidden!important;
		background: #fff!important;
		height: 20px!important;
	}
	#CP_SmartMenu .scroll-up-arrow,
	#CP_SmartMenu .scroll-down-arrow {
		position: absolute!important;
		top: -2px!important;
		left: 50%!important;
		margin-left: -8px!important;
		width: 0!important;
		height: 0!important;
		overflow: hidden!important;
		border-width: 8px!important;
        border-style: dashed dashed solid dashed!important;
        border-color: transparent transparent #fff transparent!important;
	}
	#CP_SmartMenu .scroll-down-arrow {
		top: 6px!important;
        border-style: solid dashed dashed dashed!important;
        border-color: #fff transparent transparent transparent!important;
	}
    .CP_Toggle {
        display:none!important;
    }
}
@media (max-width:1719px) {
	#CP_Slider .container-fluid,
	#CP_Hero .container-fluid {
		margin-left: 60px!important;
		margin-right: 60px!important;
		width: calc(100% - 120px)!important;
	}
}
@media (max-width: 991px) {
	#CP_Slider .container-fluid, #CP_Hero .container-fluid {
		margin-left: 30px!important;
		margin-right: 30px!important;
		width: calc(100% - 60px)!important;
	}
	#CP_TopBar a {
		padding:15px 10px!important;
	}
	#CP_Hero .overlay {
		background: rgba(0,0,0,.4)!important;
	}
    #CP_Header {
        padding-top:0!important;
    }
    #CP_Slider,
	#CP_Hero {
		text-align: center!important;
		height: auto !important;
		max-height: inherit !important;
	}
}
@media (max-width: 767px) {
	#CP_Content {
		padding:0!important;
	}
	#CP_TopBar a span {
		font-size:14px!important;
	}
}
@media (max-width:575px) {
	#CP_TopBar .fa {
		font-size:22px!important;
		color:#fff!important;
	}
}


/* Global */

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group>.btn, .input-group-btn:last-child>.dropdown-toggle, .input-group-btn:first-child>.btn:not(:first-child), .input-group-btn:first-child>.btn-group:not(:first-child)>.btn {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child>.btn, .input-group-btn:first-child>.btn-group>.btn, .input-group-btn:first-child>.dropdown-toggle, .input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child>.btn-group:not(:last-child)>.btn {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
#content table {
	width: 100%!important;
}
#ServicesHub .shadows,
#ListSub .thumbborder.thumbpad,
#ListSub table td[width="2%"],
#Guides td[width="55"],
#Guides>br {
	display: none!important;
}
#ServicesHub table td:first-child {
	display: none!important;
}
#ListSub table td {
	width: 100%!important;
	display: block!important;
	padding-bottom: 10px !important;
}
.HubSection h2 a {
	text-decoration: none!important;
	color:inherit!important;
}
.hubsection {
	height: 80px!important;
	padding: 30px 0pt 20px!important;
	border-bottom: 1px solid #888888!important;
}
.thumbborder {
	background: #fff!important;
	-webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, .5)!important;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, .5)!important;
	border: 1px solid #999!important;
	padding: 5px!important;
}
img[src$="triangle-yellow.gif"],
img[src$="triangle-orange.gif"],
img[src$="icon-calculators.png"],
img[src$="icon-faq.png"],
img[src$="icon-fg.png"] {
	display:none!important;
}

/* Map and Driving Directions */

#Map iframe,
#Directions .gadget,
#Directions iframe {
	width: 100%!important;
	border:0!important;
}
#Publications table {
	width: 100%!important;
	border: inherit!important;
	border-collapse: collapse!important;
}
#DueDates * {
	background: none!important;
	font-family: inherit!important;
	color: inherit!important;
}
#DueDates table {
	width: 100%!important;
	border-spacing: 0!important;
}
#DueDates table,
#DueDates tr,
#DueDates td {
	margin: 0!important;
	padding: 0!important;
	border: none!important;
	border-collapse: collapse!important;
}
.taxratetable {
	font-size: inherit !important;
}
.taxratetable font {
	color: inherit!important;
	font-size: inherit!important;
	font-family: inherit!important;
}
#TaxRates table {
	border: none!important;
	border-collapse: collapse!important;
}

/* Forms */

#Form table {
	border: none!important;
	border-collapse: collapse!important;
	border-spacing: 0!important;
	margin: 15px auto!important;
	width: 100%!important;
	max-width: 500px!important;
	margin: 0 auto!important;
}
#Form textarea {
	max-height:80px!important;
}
#Form form>p:first-of-type {
	text-align: center!important;
}
#Form table td {
	text-align: left!important;
	padding: 0 0 .5rem!important;
	display: block!important;
	border: none!important;
	border-collapse: collapse!important;
}
#Form #CP_Captcha br {
	display:none!important;
}
#Form #CP_Captcha,
#Form #captcha_code {
	text-align:center!important;
}
#Form #captcha_code {
    font-size: 1.8em!important;
    position: relative!important;
}
#Form #captcha_refresh {
	font-size: .8em!important;
}
#Form table td:first-child {
	font-weight: bold!important;
}
#Form table input,
#Form table textarea {
	font-weight: normal!important;
}
br.clearfloat {
	display: none!important;
}
.thumbpad {
	display: block!important;
	float: left!important;
	margin-right: 20px!important;
}
#Newsletter table td[width="220"],
#Newsletter table td[width="20"] {
	display: none!important;
}
#Newsletter .tip,
#Newsletter .note,
#Newsletter .caution, 
#Newsletter .example {
    background: none !important;
	padding: 10px 20px !important;
    min-height:0 !important;
}
#Header,
#Footer {
	display: none!important;
}
.hubtab {
	padding: 0!important;
	text-shadow: none!important;
	border-radius: 0!important;
	-webkit-box-shadow: none!important;
	box-shadow: none!important;
	float: none!important;
}
hr.headerline.cellcolor {
	display: none!important;
}
.headerline {
	box-shadow: none!important;
	-webkit-box-shadow: none!important;
	-moz-box-shadow: none!important;
	clear: both!important;
	border: 0!important;
	height: 0!important;
	margin: 0!important;
	width: 100%!important;
}
/* Blog */
#Blog .cellcolor.roundcorner {
	float:none !important;
	width:100% !important;
	border-radius: 0!important;
	border:none !important;
	padding:0 0 50px 0 !important;
}
#Blog .cellcolor li {
	margin:5px 0!important;
}
#Blog .cellcolor.roundcorner hr {
	margin:0!important;
}
#Blog .cellcolor.roundcorner div:nth-last-of-type(1),
#Blog #rssIcon {
	float:none !important;
	padding:0 !important;
	width:50% !important;
	bottom:15px !important;
}
#Blog #rssIcon {
	left:0!important;
}
#Blog .cellcolor.roundcorner div:nth-last-of-type(1) {
	left:50% !important;
}
#Blog .cellcolor.roundcorner div:nth-last-of-type(1) a,
#Blog #rssIcon a {
	display: block!important;
}
#Blog .cellcolor.roundcorner br {
	display:none!important;
}
@media (any-pointer:coarse), (any-hover:none) {
    .parallax { 
		background-attachment: initial !important
	}
}
td, h1, div {
	line-height : inherit!important;
}

/* Start MENU */
